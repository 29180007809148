.label-check, .ul{
    transition: .2s ease-in-out;
}

.label-check:hover + ul.ul{
    opacity: 1;
}

.li-label{
    margin-top: 30px;
}

.label-mob{
    color: #06a3da;
}

.label-check:hover{
    color: #06a3da;
    cursor: default;
}

.ul:hover{
    opacity: 1;
}

.location-mob{
    font-size: 16px;
    display: flex;
    align-items: center;
}

.nav-link{
    transition: .2s ease-in-out;
}

.nav-link-mobile{
    width: 100%;
    height: 40px;
    background-color: #edf4fb;
    text-align: center;
    display: flex;
    transition: .2s;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.nav-link:hover{
    color: #06a3da;
}

.menu{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #edf4ff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.hamburguer{
    background-color: #37a0cd;
    position: relative;
    display: block;
    width: 30px;
    height: 2px;
    transition: 0.5s ease-in-out;
    border-radius: 50%;
}

.hamburguer::before, .hamburguer::after{
    content: '';
    background-color: #37a0cd;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.2s ease-in-out;
    border-radius: 50%;
}

.hamburguer::before{
    top: -10px;
}

.hamburguer::after{
    bottom: -10px;
}

input:checked ~ label .hamburguer{
    transform: rotate(45deg);
}

input:checked ~ label .hamburguer::before{
    transform: rotate(90deg);
    top: 0;
}

input:checked ~ label .hamburguer::after{
    transform: rotate(90deg);
    bottom: 0;
}

input:checked ~ .nav-mob{
    display: flex;
}

.insta-mob{
    background: radial-gradient(circle at 30% 107%, #fdf497 5%, #fd5949 35%,#d6249f 60%,#285AEB 90%);
}

.face-mob{
    background-color: #1873eb;
}

.option-li{
    position: relative;
}